/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialAdminState } from '../state/admin.state';
import {
  deleteDivisionByOrganizationIdAndDivisionId,
  deleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId,
  fetchDivisionByOrganizationIdAndDivisionId,
  fetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId,
  updateDivisionByOrganizationIdAndDivisionId,
  updateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId,
} from 'app/views/app/league/administration/admin.service';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: initialAdminState,
  reducers: {
    setAdminDivisions: (state, action) => {
      state.division.divisions = action.payload;
    },
    setAdminSubDivisions: (state, action) => {
      state.subDivision.subDivisions = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setSelectedDivision: (state, action) => {
      state.division.selectedDivision = action.payload;
      state.subDivision.selectedSubDivision = null;
    },
    setSelectedSubDivision: (state, action) => {
      state.subDivision.selectedSubDivision = action.payload;
    },
  },
  extraReducers: (builder) => {
    // division
    builder.addCase(fetchDivisionByOrganizationIdAndDivisionId.pending, (state) => {
      state.division.loading = true;
    });
    builder.addCase(
      fetchDivisionByOrganizationIdAndDivisionId.fulfilled,
      (state, action) => {
        state.division.loading = false;
        state.division.selectedDivision = action.payload;
        state.division.error = null;
      }
    );
    builder.addCase(
      fetchDivisionByOrganizationIdAndDivisionId.rejected,
      (state, action) => {
        state.division.loading = false;
        state.division.error = action.error.message;
      }
    );

    builder.addCase(deleteDivisionByOrganizationIdAndDivisionId.pending, (state) => {
      state.division.loading = true;
    });
    builder.addCase(
      deleteDivisionByOrganizationIdAndDivisionId.fulfilled,
      (state, action) => {
        state.division.loading = false;
        state.division.selectedDivision = null;
        state.division.error = null;
      }
    );
    builder.addCase(
      deleteDivisionByOrganizationIdAndDivisionId.rejected,
      (state, action) => {
        state.division.loading = false;
        state.division.error = action.error.message;
      }
    );

    builder.addCase(updateDivisionByOrganizationIdAndDivisionId.pending, (state) => {
      state.division.loading = true;
    });
    builder.addCase(
      updateDivisionByOrganizationIdAndDivisionId.fulfilled,
      (state, action) => {
        state.division.loading = false;
        state.division.selectedDivision = action.payload;
        state.division.error = null;
      }
    );
    builder.addCase(
      updateDivisionByOrganizationIdAndDivisionId.rejected,
      (state, action) => {
        state.division.loading = false;
        state.division.error = action.error.message;
      }
    );

    // sub-division
    builder.addCase(
      fetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.pending,
      (state) => {
        state.subDivision.loading = true;
      }
    );
    builder.addCase(
      fetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.fulfilled,
      (state, action) => {
        state.subDivision.loading = false;
        state.subDivision.selectedSubDivision = action.payload;
        state.subDivision.error = null;
      }
    );
    builder.addCase(
      fetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.rejected,
      (state, action) => {
        state.subDivision.loading = false;
        state.subDivision.error = action.error.message;
      }
    );

    builder.addCase(
      deleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.pending,
      (state) => {
        state.subDivision.loading = true;
      }
    );
    builder.addCase(
      deleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.fulfilled,
      (state, action) => {
        state.subDivision.loading = false;
        state.subDivision.selectedSubDivision = null;
        state.subDivision.error = null;
      }
    );
    builder.addCase(
      deleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.rejected,
      (state, action) => {
        state.subDivision.loading = false;
        state.subDivision.error = action.error.message;
      }
    );

    builder.addCase(
      updateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.pending,
      (state) => {
        state.subDivision.loading = true;
      }
    );
    builder.addCase(
      updateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.fulfilled,
      (state, action) => {
        state.subDivision.loading = false;
        state.subDivision.selectedSubDivision = action.payload;
        state.subDivision.error = null;
      }
    );
    builder.addCase(
      updateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId.rejected,
      (state, action) => {
        state.subDivision.loading = false;
        state.subDivision.error = action.error.message;
      }
    );
  },
});

export const { actions: adminActions, reducer: adminReducer } = adminSlice;
export const {
  setAdminDivisions,
  setAdminSubDivisions,
  setSelectedDivision,
  setSelectedSubDivision,
  setSelectedOrganization,
} = adminActions;
