import { IOrganization } from '@dex/models/organization.model';
import { AdminDivisionState } from './admin-division.state';
import { AdminSubDivisionState } from './admin-sub-division.state';

type IAdminState = {
  division: AdminDivisionState;
  subDivision: AdminSubDivisionState;
  selectedOrganization: IOrganization | null;
  error: any;
  loading: boolean;
};

const initialAdminState: IAdminState = {
  selectedOrganization: null,
  division: {
    divisions: [],
    selectedDivision: null,
    error: null,
    loading: false,
  },
  subDivision: {
    subDivisions: [],
    selectedSubDivision: null,
    error: null,
    loading: false,
  },
  error: null,
  loading: false,
};

export { initialAdminState };
export type { IAdminState };
