import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { IDivision, ISubDivision } from '@dex/models/division.model';
import { UpdateDivisionRequestBody } from './models/update-division-request-body.model';
import { CreateDivisionRequestBody } from './models/create-division-request-body.model';
import { CreateSubDivisionRequestBody } from './models/create-sub-division-request-body.model';
import { UpdateSubDivisionRequestBody } from './models/update-sub-division-request-body.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

export const fetchDivisionByOrganizationIdAndDivisionId = createAsyncThunk(
  'admin/fetchDivisionByOrganizationIdAndDivisionId',
  async ({
    organizationId,
    divisionId,
  }: {
    organizationId: string;
    divisionId: string;
  }) => {
    const url = `${baseUrl}/division`;

    const config: AxiosRequestConfig = {
      params: {
        organizationId: organizationId,
        divisionId: divisionId,
      },
    };
    const response = await axios.get(url, config);

    if (response.status !== 200) {
      throw new Error('Error fetching division');
    }

    return response.data as IDivision;
  }
);

export const updateDivisionByOrganizationIdAndDivisionId = createAsyncThunk<
  IDivision,
  {
    organizationId: string;
    divisionId: string;
    updateDivisionBody: UpdateDivisionRequestBody;
  },
  {}
>(
  'admin/updateDivisionByOrganizationIdAndDivisionId',
  async ({ organizationId, divisionId, updateDivisionBody }) => {
    const url = `${baseUrl}/division`;

    const config: AxiosRequestConfig = {
      params: {
        organizationId: organizationId,
        divisionId: divisionId,
      },
    };

    const response = await axios.put(url, updateDivisionBody, config);

    if (response.status !== 200) {
      throw new Error('Error updating division');
    }

    return response.data as IDivision;
  }
);

export const deleteDivisionByOrganizationIdAndDivisionId = createAsyncThunk<
  IDivision,
  {
    organizationId: string;
    divisionId: string;
  },
  {}
>(
  'admin/deleteDivisionByOrganizationIdAndDivisionId',
  async ({ organizationId, divisionId }) => {
    const url = `${baseUrl}/division`;

    const config: AxiosRequestConfig = {
      params: {
        organizationId: organizationId,
        divisionId: divisionId,
      },
    };

    const response = await axios.delete(url, config);

    if (response.status !== 200) {
      throw new Error('Error deleting division');
    }

    return response.data as IDivision;
  }
);

export const createDivisionByOrganizationId = createAsyncThunk<
  IDivision,
  {
    organizationId: string;
    createDivisionBody: CreateDivisionRequestBody;
  },
  {}
>(
  'admin/createDivisionByOrganizationIdAndDivisionId',
  async ({ organizationId, createDivisionBody }) => {
    const url = `${baseUrl}/division`;

    const body = {
      ...createDivisionBody,
      organizationId: organizationId,
    };

    const response = await axios.post(url, body);

    if (response.status !== 200) {
      throw new Error('Error creating division');
    }

    return response.data as IDivision;
  }
);

export const fetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId =
  createAsyncThunk(
    'admin/fetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId',
    async ({
      organizationId,
      divisionId,
      subDivisionId,
    }: {
      organizationId: string;
      divisionId: string;
      subDivisionId: string;
    }) => {
      const url = `${baseUrl}/sub-division`;

      const config: AxiosRequestConfig = {
        params: {
          organizationId: organizationId,
          divisionId: divisionId,
          subDivisionId: subDivisionId,
        },
      };
      const response = await axios.get(url, config);

      if (response.status !== 200) {
        throw new Error('Error fetching sub-division');
      }
      return response.data as ISubDivision;
    }
  );

export const updateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId =
  createAsyncThunk<
    ISubDivision,
    {
      organizationId: string;
      divisionId: string;
      subDivisionId: string;
      updateSubDivisionBody: UpdateSubDivisionRequestBody;
    },
    {}
  >(
    'admin/updateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId',
    async ({ organizationId, divisionId, subDivisionId, updateSubDivisionBody }) => {
      const url = `${baseUrl}/sub-division`;

      const config: AxiosRequestConfig = {
        params: {
          organizationId: organizationId,
          divisionId: divisionId,
          subDivisionId: subDivisionId,
        },
      };

      const response = await axios.put(url, updateSubDivisionBody, config);

      if (response.status !== 200) {
        throw new Error('Error updating sub-division');
      }

      return response.data as ISubDivision;
    }
  );

export const deleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId =
  createAsyncThunk<
    ISubDivision,
    {
      organizationId: string;
      divisionId: string;
      subDivisionId: string;
    },
    {}
  >(
    'admin/deleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId',
    async ({ organizationId, divisionId, subDivisionId }) => {
      const url = `${baseUrl}/sub-division`;

      const config: AxiosRequestConfig = {
        params: {
          organizationId: organizationId,
          divisionId: divisionId,
          subDivisionId: subDivisionId,
        },
      };

      const response = await axios.delete(url, config);

      if (response.status !== 200) {
        throw new Error('Error deleting sub-division');
      }

      return response.data as ISubDivision;
    }
  );

export const createSubDivisionByOrganizationIdAndDivisionId = createAsyncThunk<
  ISubDivision,
  {
    organizationId: string;
    divisionId: string;
    createSubDivisionBody: CreateSubDivisionRequestBody;
  },
  {}
>(
  'admin/createSubDivisionByOrganizationIdAndDivisionId',
  async ({ organizationId, divisionId, createSubDivisionBody }) => {
    const url = `${baseUrl}/sub-division`;

    const body = {
      ...createSubDivisionBody,
      organizationId: organizationId,
      divisionId: divisionId,
    };

    const response = await axios.post(url, body);

    if (response.status !== 200) {
      throw new Error('Error creating division');
    }

    return response.data as ISubDivision;
  }
);

const useFetchDivisionByOrganizationIdAndDivisionId = (
  organizationId: string,
  divisionId: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const division = useSelector(
    (state: RootState) => state.admin.division.selectedDivision
  );
  const loading = useSelector((state: RootState) => state.admin.division.loading);
  const error = useSelector((state: RootState) => state.admin.division.error);

  useEffect(() => {
    if (organizationId && divisionId)
      dispatch(
        fetchDivisionByOrganizationIdAndDivisionId({ organizationId, divisionId })
      );
  }, [dispatch, organizationId, divisionId]);

  return { division, loading, error };
};

const useUpdateDivisionByOrganizationIdAndDivisionId = (
  organizationId: string,
  divisionId: string,
  updateDivisionBody: UpdateDivisionRequestBody
) => {
  const dispatch = useDispatch<AppDispatch>();
  const division = useSelector(
    (state: RootState) => state.admin.division.selectedDivision
  );
  const loading = useSelector((state: RootState) => state.admin.division.loading);
  const error = useSelector((state: RootState) => state.admin.division.error);

  useEffect(() => {
    if (organizationId && divisionId)
      dispatch(
        updateDivisionByOrganizationIdAndDivisionId({
          organizationId,
          divisionId,
          updateDivisionBody,
        })
      );
  }, [dispatch, organizationId, divisionId, updateDivisionBody]);

  return { division, loading, error };
};

const useDeleteDivisionByOrganizationIdAndDivisionId = (
  organizationId: string,
  divisionId: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const division = useSelector(
    (state: RootState) => state.admin.division.selectedDivision
  );
  const loading = useSelector((state: RootState) => state.admin.division.loading);
  const error = useSelector((state: RootState) => state.admin.division.error);

  useEffect(() => {
    if (organizationId && divisionId)
      dispatch(
        deleteDivisionByOrganizationIdAndDivisionId({
          organizationId,
          divisionId,
        })
      );
  }, [dispatch, organizationId, divisionId]);

  return { division, loading, error };
};

const useCreateDivisionByOrganizationId = (
  organizationId: string,
  createDivisionBody: CreateDivisionRequestBody
) => {
  const dispatch = useDispatch<AppDispatch>();
  const division = useSelector(
    (state: RootState) => state.admin.division.selectedDivision
  );
  const loading = useSelector((state: RootState) => state.admin.division.loading);
  const error = useSelector((state: RootState) => state.admin.division.error);

  useEffect(() => {
    if (organizationId)
      dispatch(
        createDivisionByOrganizationId({
          organizationId,
          createDivisionBody,
        })
      );
  }, [dispatch, organizationId, createDivisionBody]);

  return { division, loading, error };
};

const useFetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId = (
  organizationId: string,
  divisionId: string,
  subDivisionId: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const subDivision = useSelector(
    (state: RootState) => state.admin.subDivision.selectedSubDivision
  );
  const loading = useSelector((state: RootState) => state.admin.subDivision.loading);
  const error = useSelector((state: RootState) => state.admin.subDivision.error);

  useEffect(() => {
    if (organizationId && divisionId && subDivisionId) {
      dispatch(
        fetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId({
          organizationId,
          divisionId,
          subDivisionId,
        })
      );
    }
  }, [dispatch, organizationId, divisionId, subDivisionId]);

  return { subDivision, loading, error };
};

const useUpdateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId = (
  organizationId: string,
  divisionId: string,
  subDivisionId: string,
  updateSubDivisionBody: UpdateSubDivisionRequestBody
) => {
  const dispatch = useDispatch<AppDispatch>();
  const subDivision = useSelector(
    (state: RootState) => state.admin.subDivision.selectedSubDivision
  );
  const loading = useSelector((state: RootState) => state.admin.subDivision.loading);
  const error = useSelector((state: RootState) => state.admin.subDivision.error);

  useEffect(() => {
    if (organizationId && divisionId && subDivisionId)
      dispatch(
        updateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId({
          organizationId,
          divisionId,
          subDivisionId,
          updateSubDivisionBody,
        })
      );
  }, [dispatch, organizationId, divisionId, subDivisionId, updateSubDivisionBody]);

  return { subDivision, loading, error };
};

const useDeleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId = (
  organizationId: string,
  divisionId: string,
  subDivisionId: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const subDivision = useSelector(
    (state: RootState) => state.admin.subDivision.selectedSubDivision
  );
  const loading = useSelector((state: RootState) => state.admin.subDivision.loading);
  const error = useSelector((state: RootState) => state.admin.subDivision.error);

  useEffect(() => {
    if (organizationId && divisionId && subDivisionId)
      dispatch(
        deleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId({
          organizationId,
          divisionId,
          subDivisionId,
        })
      );
  }, [dispatch, organizationId, divisionId, subDivisionId]);

  return { subDivision, loading, error };
};

const useCreateSubDivisionByOrganizationIdAndDivisionId = (
  organizationId: string,
  divisionId: string,
  createSubDivisionBody: CreateSubDivisionRequestBody
) => {
  const dispatch = useDispatch<AppDispatch>();
  const division = useSelector(
    (state: RootState) => state.admin.division.selectedDivision
  );
  const loading = useSelector((state: RootState) => state.admin.division.loading);
  const error = useSelector((state: RootState) => state.admin.division.error);

  useEffect(() => {
    if (organizationId)
      dispatch(
        createSubDivisionByOrganizationIdAndDivisionId({
          organizationId,
          divisionId: divisionId,
          createSubDivisionBody,
        })
      );
  }, [dispatch, organizationId, divisionId, createSubDivisionBody]);

  return { division, loading, error };
};

export {
  // Division
  useCreateDivisionByOrganizationId,
  useDeleteDivisionByOrganizationIdAndDivisionId,
  useFetchDivisionByOrganizationIdAndDivisionId,
  useUpdateDivisionByOrganizationIdAndDivisionId,
  // Sub-Division
  useFetchSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId,
  useUpdateSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId,
  useCreateSubDivisionByOrganizationIdAndDivisionId,
  useDeleteSubDivisionByOrganizationIdAndDivisionIdAndSubDivisionId,
};
